import {createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";

import {BtRepresentationFull, Theater} from "../core/types";
import useShowsRepo from "../hooks/api/useShowsRepo";
import {SeatCategories} from "../core/enums";
import useTheatersRepo from "../hooks/api/useTheatersRepo";

interface MainContextInterface {
    rep: BtRepresentationFull,
    setRep: Dispatch<SetStateAction<BtRepresentationFull>>;
    loadRep: (id: string) => void;
    getPriceName: (cat: SeatCategories) => {name: string, price: number};
    theater: Theater;
    unselectSeats: () => void;
    error: boolean;
}

export const MainContextProvider = ({ children }: { children: ReactNode }) => {
    const [rep, setRep] = useState<BtRepresentationFull>({
        showName: '',
        roomName: '',
        roomAddress: '',
        showNameEng: '',
        roomNameEng: '',
        roomAddressEng: '',
        roomLatitude: 0,
        roomLongitude: 0,
        freeSeats: 0,
        id: '',
        date: new Date(),
        seats: [],
        bulkSeats: [],
        objects: [],
        seatCategories: []
    });
    const [theater, setTheater] = useState<Theater>({} as Theater);
    const { getRepresentationDetailsBySlug  } = useShowsRepo();
    const { getTheater, error } = useTheatersRepo();

    const loadRep = async (repId: string) => {
        const rep = await getRepresentationDetailsBySlug(repId);
        if (rep) setRep(rep);
    }

    const loadTheater = async () => {
        const t = await getTheater();
        if (t) setTheater(t);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        loadTheater().catch(null);
    },[]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const getPriceName = (cat: SeatCategories): {name: string, price: number} => {
        const selCat = rep.seatCategories.find(c => c.category === cat);
        if (selCat)
            return {name: selCat.name, price: selCat.price}
        return {name:"", price: 0};
    }

    const unselectSeats = () => {
        if (rep)
            rep.seats.filter(s => s.isSelected).map(s => s.isSelected = false);
    }

    return (
        <MainContext.Provider
            value={{
                rep,
                setRep,
                loadRep,
                getPriceName,
                theater,
                unselectSeats,
                error: error
            }}>
            {children}
        </MainContext.Provider>
    );
};

export const MainContext = createContext<MainContextInterface>(null!);


